<template>
  <v-container class="pa-0" fluid>
    <page-title :heading="$t('customers.lang_customerDepartments')"
                :subheading="$t('customers.lang_customerDepartmentsEdit')"
                :icon="icon"
                show-previous-button
    ></page-title>
    <div class="app-main__inner">
      <EditDepartment/>
    </div>
  </v-container>
</template>

<script>
import PageTitle from "../../../../Layout/Components/PageTitle";
import EditDepartment from "../../../../components/customers/customersettings/department/EditDepartment";
export default {
  name: "EditDepartmentView",
  components: {EditDepartment, PageTitle},
  data: () => ({
    icon: 'pe-7s-tools icon-gradient bg-tempting-azure',
  }),
}
</script>

<style scoped>

</style>